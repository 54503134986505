<template>
  <b-field v-if="!disabled" :expanded="true">
    <template #label>
      <base-label :label="label" :tooltip="tooltip" />
    </template>
    <b-select v-model="localValue" :expanded="true">
      <option :selected="localValue === ''" value="">
        {{ $t("UI.enum.general.SELECT") }}
      </option>
      <option
        v-for="item in appraisers"
        :key="item.locationCode"
        :selected="item.locationCode === localValue"
        :value="item.locationCode"
      >
        {{ item.name }}
      </option>
      <option value="OTHER" :selected="localValue === 'OTHER'">
        {{ $t("UI.enum.general.OTHER") }}
      </option>
    </b-select>
  </b-field>
  <div v-else class="field">
    <base-label :embedded="false" :label="label" :tooltip="tooltip" />
    <div class="control">{{ locationName }}</div>
  </div>
</template>

<script lang="js">
import Vue from "vue";
import { Reporter } from "@/common/enums";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String,
      required: false,
      default: "",
    },
    appraisers: {
      type: Array,
    },
  },
  data() {
    return {
      Reporter,
    };
  },
  computed: {
    locationName() {
      if (this.localValue === "") {
        return "-";
      } else {
        const location = Array.from(this.appraisers).find(
          (location) => location.locationCode === this.localValue
        );
        if (location) {
          return location.name;
        } else {
          return "-";
        }
      }
    },
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("valueChanged", value);
      },
    }
  },
});
</script>
