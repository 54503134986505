
import Vue, { PropType } from "vue";
import { SimpleUserDTO } from "@/common/dto";
import i18n from "@/ui/i18n";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    appraiser: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  methods: {
    compute_name(item: SimpleUserDTO) {
      return item.fullName;
    },
    async updateEmployees() {
      await new DataHttpClient()
        .allAppraisersUsers(this.appraiser)
        .then((res) => (this.items = res))
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.data.appraisersUsers"),
            err.message
          );
        });
    },
  },
  data() {
    return {
      items: [] as SimpleUserDTO[],
    };
  },
  computed: {
    localValue: {
      get: function (): string {
        return this.value;
      },
      set: function (value: string) {
        this.$emit("valueChanged", value);
      },
    },
    name(): string {
      const result = this.items.find((obj) => {
        return obj.username === this.localValue;
      });

      return result !== undefined
        ? this.compute_name(result)
        : i18n.t("UI.message.notAvailable");
    },
  },
  watch: {
    appraiser: {
      handler: "updateEmployees",
      immediate: true,
    },
  },
});
